<template>
    <PageLayout>
        <template>
            <div class="d-flex justify-space-between mb-6">
                <div class="d-flex align-center flex-grow-1">
                    <h1>
                        <template>
                            {{ $t('tasks') }}
                        </template>
                    </h1>
                </div>
                <v-col v-if="!$vuetify.breakpoint.xsOnly" cols="12" sm="4" v-show="!filter_show" class="text-right">
                    <v-icon :disabled="userBlock" v-if="can(['administrator'])" class="mr-2" color="primary" @click="$router.push({name: 'task'})">
                        mdi-view-headline
                    </v-icon>
                    <v-icon :disabled="userBlock" v-if="can(['administrator'])" class="mr-5" color="primary" @click="$router.push({name: 'task.kanban'})">
                        mdi-format-columns
                    </v-icon>
                </v-col>
                <div class="text-right">
                    <Btn
                        v-if="$vuetify.breakpoint.smAndUp"
                        :disabled="userBlock"
                        :to="{name: 'task.create'}"
                        color="secondary"
                    >
                        {{ $t('task.add') }}
                    </Btn>
                    <Teleport to="#app" v-else>
                        <div class="fab__wrapper">
                            <Btn
                                fab
                                :disabled="userBlock"
                                :to="{name: 'task.create'}"
                                :title="$t('task.add')"
                                color="secondary"
                            ><v-icon>mdi-plus</v-icon></Btn>
                        </div>
                    </Teleport>
                </div>
            </div>
            <v-container fluid>
                <div class="kanban">

                    <div
                        v-for="(item, index) in task_statusItems"
                        :key="item.slug + '_' + index"
                        :class="{'disabled-column': !item.disabled}"
                    >
                        <div>{{item.name}} - {{item.task_total}}</div>
                        <v-divider class="my-1 mb-5 my_hr" :style="'border-color:'+item.color"></v-divider>

                        <draggable :disabled="!item.disabled" class="list-group"
                                   :list="item.tasks"
                                   group="people"
                                   @change="changeDrag($event,item.action)"
                                   @start="startDrag(item,index,item.tasks)"
                                   @end="endDrag"
                        >

                            <!-- :class="{'disabled-column': !item.disabled}" В div class="list-group-item" вставить тогда можно сделать какие можно карточки переносит а какие нет -->
                            <div
                                class="list-group-item"
                                v-for="(element, index) in item.tasks"
                                :key="element.number + '_' + index"

                            >
                                <div class="kanban_task" :style="'border-left: 3px solid' +  item.color">
                                    <div class="body-2 kanban_link" @click="showTask(element)">{{ element.title }}</div>
                                    <v-col cols="12" class="text-right">
                                        <v-avatar size="36" class="border_blue my-0 py-0">
                                            <img v-if="element.admin.photo !== '/img/avatar.png'" :src="element.admin.photo" :alt="element.admin.name">
                                            <v-icon v-else color="primary" size="22">
                                                ${{'settingsIcon'}}
                                            </v-icon>
                                        </v-avatar>
                                    </v-col>
                                </div>
                            </div>

                        </draggable>

                    </div>
                </div>
            </v-container>
        </template>
        <template v-slot:side>
            <v-icon v-if="$vuetify.breakpoint.mdAndUp" class="mt-2 mb-5" :disabled="userBlock" color="secondary" @click="filter_show===true ? filter_show=false : filter_show=true" :title="$t['filter_add']">mdi-filter-plus</v-icon>

            <v-list :disabled="userBlock" class="filter_left" v-if="Additional.length > 0 && $vuetify.breakpoint.mdAndUp">
                <v-list-group :ripple="false" v-model="group2" class="px-0">
                    <template v-slot:activator class="px-0">
                        <v-list-item-title :ripple="false" class="main_filter">{{ $t('system_filters') }}</v-list-item-title>
                    </template>
                    <v-list-item :ripple="false" v-for="(item, i) in Additional" :key="i" :to="item.url" :class="{'active-item': isSameRouteWithQueryParams($route, item.url)}">
                        <v-list-item-title v-if="item.can" v-text="item.title" class="cursor-pointer"></v-list-item-title>
                        <v-list-item-icon v-if="item.can" v-text="item.count" class="filter_count"></v-list-item-icon>
                    </v-list-item>
                </v-list-group>
            </v-list>
        </template>
    </PageLayout>

</template>
<script>

    import {mapGetters} from "vuex"
    import draggable from "vuedraggable";
    import PageLayout from "@/components/Leentech/PageLayout.vue";
    import FilterView from "@/plugins/mixins/FilterView";
    import NavMixin from "@/plugins/mixins/Nav";
    import Btn from "@/components/Form/Btn.vue";

    export default {
        name: "TaskKanban",
        mixins: [
            FilterView,
            NavMixin
        ],
        components: {
            draggable,
            PageLayout,
            Btn
        },
        data() {
            return {
                loading: false,
                drag: false,
                task_statusItems: [],
                task_status: null,
                page: 1,
                get_scroll: 1,
                number: null,
                title: null,
                perPage: 15,
                startTask: [],



                status_delete:null,
                uuid_delete:null,
                status_cancel:null,
                task_type_id:null,
                task_drag:{},
                headerBets: [
                    {
                        text: this.$t('supplier'),
                        align: "left",
                        sortable: false,
                        value: "supplier.name"
                    },
                    {
                        text: this.$t('type_transport'),
                        align: "left",
                        sortable: false,
                        value: "type_transport.name"
                    },
                    {
                        text: this.$t('type_container'),
                        align: "left",
                        sortable: false,
                        value: "type_container.name"
                    },
                    {
                        text: this.$t('date_dispatch'),
                        align: "left",
                        sortable: false,
                        value: "date_dispatch"
                    },
                    {
                        text: this.$t('price'),
                        align: "left",
                        sortable: false,
                        value: "price"
                    },
                ],
                subordinates: false,

                group2: true,
                filter_show: false,
                Additional: [
                    {
                        'title': this.$t('my_tasks'),
                        'count': '-',
                        'action': 'all',
                        'can': 1,
                        'url': {
                            path: '/task/kanban',
                        }
                    },
                    {
                        'title': this.$t('inbox'),
                        'count': '-',
                        'action': 'inbox',
                        'can': 1,
                        'url': {
                            path: '/task/kanban',
                            query: {
                                filter: {
                                    inbox: {
                                        custom: '1'
                                    },
                                }
                            }
                        }
                    },
                    {
                        'title': this.$t('outbox'),
                        'count': '-',
                        'action': 'outbox',
                        'can': 1,
                        'url': {
                            path: '/task/kanban',
                            query: {
                                filter: {
                                    outbox: {
                                        custom: '1'
                                    },
                                }
                            }
                        }
                    },
                    {
                        'title': this.$t('favorite'),
                        'count': '-',
                        'action': 'favorite',
                        'can': 1,
                        'url': {
                            path: '/task/kanban',
                            query: {
                                filter: {
                                    favorite: {
                                        custom: '1'
                                    }
                                }
                            }
                        }
                    },
                    {
                        'title': this.$t('overdue'),
                        'count': '-',
                        'action': 'overdue',
                        'can': 1,
                        'url': {
                            path: '/task/kanban',
                            query: {
                                filter: {
                                    deadline: {
                                        lte: this.$moment().format('YYYY-MM-DD')
                                    },
                                    task_status_id: {
                                        in: ['1', '2']
                                    }
                                }
                            }
                        }
                    },
                    {
                        'title': this.$t('urgent'),
                        'count': '-',
                        'action': 'urgent',
                        'can': 1,
                        'url': {
                            path: '/task/kanban',
                            query: {
                                filter: {
                                    is_urgent: {
                                        eq: '1'
                                    },
                                    task_status_id: {
                                        notin: ['3', '5']
                                    }
                                }
                            }
                        }
                    },
                    {
                        'title': this.$t('subordinates'),
                        'count': '-',
                        'action': 'admin_all',
                        'can': this.can(['director', 'administrator']),
                        'url': {
                            path: '/task/kanban',
                            query: {
                                filter: {
                                    all_tasks: {
                                        eq: '1'
                                    },
                                }
                            }
                        }
                    },
                ],
            };
        },
        computed: {
            ...mapGetters(['lang', 'itemsPerPage', 'perPageItems', 'userBlock']),
            dragOptions() {
                return {
                    animation: 200,
                    group: "description",
                    disabled: false,
                    ghostClass: "ghost"
                };
            }
        },
        async mounted() {
            await this.getTaskStatuses()
            // Ждём, пока Vue рендерит DOM и получаем родителя
            this.$nextTick(() => {
                const container = this.$parent.$refs.mainContent; // Доступ к ref родителя
                if (container) {
                    container.addEventListener("scroll", this.handleScroll, { passive: true });
                }
            });
            this.init();
        },
        destroyed() {
            const container = this.$parent.$refs.mainContent;
            if (container) {
                container.removeEventListener("scroll", this.handleScroll);
            }
        },

        methods: {
            async init() {
                await this.getItems();
            },
            getItems() {
                this.page = 1;
                this.getTasks()
            },
            async handleScroll() {

                const container = this.$parent.$refs.mainContent; // Берём родительский div
                if (!container) return;

                const scrollTop = container.scrollTop;
                const scrollHeight = container.scrollHeight;
                const clientHeight = container.clientHeight;

                if (scrollTop + clientHeight >= scrollHeight - 1 && this.get_scroll === 1) {
                    this.page++;
                    await this.getTasks();
                }
            },
            startDrag(item, index, tasks){
               this.startTask = Object.assign([], tasks);
               const indexToComplete = this.task_statusItems.findIndex(obj => obj.action ===  'complete');
               this.task_statusItems[indexToComplete].disabled =  false;
               if(item.action !== ''){
                   for (let i=0; i<index; i++){
                       this.task_statusItems[i].disabled = false;
                   }
                   if(item.action === 'pause'){
                       this.task_statusItems[1].disabled = true;
                   }
                   else if(item.action === 'approved'){
                       this.task_statusItems[indexToComplete].disabled = true;
                   }
                   else if(item.action === 'complete'){
                       for (const value of this.task_statusItems) {
                           value.disabled = true
                       }
                   }
               }
            },
            async  endDrag(evt){

                if(this.status_delete === 'approved' && this.task_type_id === 3){
                   //dsad
                    // error обратно отправить вызвать метод this.cancelDrag();
                }
                else if(this.status_delete === 'done' && this.task_type_id === 3){

                    await this.editStatus();
                }

                else if(this.status_delete != null && this.task_type_id != null) {
                    await this.editStatus();
                }

                for (const value of this.task_statusItems) {
                    value.disabled = true
                }
            },
            async changeDrag(evt, status) {
                if(evt && evt.added && evt.added.element && evt.added.element.uuid){
                    this.status_delete = status;
                    this.uuid_delete = evt.added.element.uuid;
                    this.task_type_id = evt.added.element.task_type_id;
                }
                if(evt && evt.removed && evt.removed.element)
                {
                    this.status_cancel = status;
                }

            },

            taskStatusDisabledTrue(){
                for (const value of this.task_statusItems) {
                    value.disabled = true
                }
            },
            async getTaskStatuses() {
                this.loading = true
                let params = {}
                await this.$http
                    .get("admin/task_status", {
                        params: params,
                    })
                    .then(res => {
                        this.task_statusItems = res.body.data
                    })
                    .catch(err => {
                        this.task_statusItems = []
                    })
                    .finally(end => {
                        this.loading = false
                    })
            },
            async getTasks() {
                this.get_scroll = 0;
                for (const value of this.task_statusItems) {
                    this.loading = true;
                    let params = {};
                    params.sortBy = 'id';
                    params.sortDir = 'desc';
                    params.perPage = this.perPage;
                    params.page = this.page
                    params.active = 1

                    if (this.number) {
                        params.number = this.number;
                    }
                    if (this.title) {
                        params.title = this.title;
                    }
                    let add_filter = '';
                    if(this.subordinates === true){
                       add_filter = 'fltr[all_tasks][eq]=1&';
                    }
                    if (this.filterValues) {
                        params.fltr = this.filterValues;
                    }

                    await this.$http
                        .get("admin/task?"+add_filter+"fltr[task_status_id][eq]="+value.id, {
                       // .get("admin/task?fltr[all_tasks][eq]=1&fltr[task_status_id][eq]="+value.id, {
                            params: params,
                        })
                        .then(res => {
                            if(this.page === 1){
                                this.get_scroll = 1;
                                value.tasks = res.body.data
                                value.task_total = res.body.meta.total;
                            }
                            else {
                                for (const item of res.body.data) {
                                    this.get_scroll = 1;
                                    value.tasks.push(item);
                                }
                            }
                        })
                        .catch(err => {
                            this.tasks = []
                            this.totalTasks = 0
                            this.$toastr.error(this.$t('failed_to_get_list_tasks'))
                        })
                        .finally(end => {
                            this.loading = false
                        })
                }
            },
            async editStatus() {
                var _this = this;
                this.progress = 0
                var formData = new FormData()
                let err = 0;
                let task_uuid = this.uuid_delete;
                let status =this.status_delete;



                if (this.languages && this.languages[this.tab]) {
                    formData.append('language', this.languages[this.tab])

                }
                if(this.task_type_id){
                    formData.append('task_type',  this.task_type_id)
                }


                if (this.field_status_task) {
                    if (this.field_status_task.id) {
                        formData.append('field_status_task', this.field_status_task.id)
                    } else {
                        formData.append('field_status_task', this.field_status_task)
                    }
                }



                formData.append('task_status', status)
                formData.append('id_array', 1000 )

                this.loading = true


                await this.$http
                    .put(`admin/task/status/${task_uuid}`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.closeForm()
                        this.$toastr.success(this.$t('task_has_been_updated'))
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('task_has_not_been_updated'))
                        this.cancelDrag();
                        this.closeForm();
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }

                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })


            },
            showTask(item) {
                this.$router.push({
                    name: 'task.show',
                    params: {
                        id: item.uuid
                    }
                })
            },

            cancelDrag(){
                const item = this.task_statusItems.find(obj => obj.action ===  this.status_delete);
                const indexToRemove = item.tasks.findIndex(item => item.uuid === this.uuid_delete);
                if (indexToRemove !== -1) {
                    item.tasks.splice(indexToRemove, 1);
                }
                const item2 = this.task_statusItems.find(obj => obj.action ===  this.status_cancel);
                item2.tasks = this.startTask;
                this.startTask = []
                this.status_delete = null;
                this.uuid_delete = null;
                this.status_cancel = null;
                this.task_type_id = null;
            },
            closeForm(){

                this.startTask = []
                this.status_delete = null;
                this.uuid_delete = null;
                this.status_cancel = null;
                this.task_type_id = null;
            },


            setSubordinates(){
                for (const value of this.task_statusItems) {
                    value.tasks = []
                }
                this.page = 1;
                if(this.subordinates === false){
                    this.subordinates = true;
                }
                else{
                    this.subordinates = false;
                }
                this.getTasks();
            }
        }
    }
</script>

<style>
.kanban{
    flex-grow: 1;display:flex;margin-bottom: 1rem; overflow: auto
}
.kanban > div{
    flex-shrink: 0;width: 320px;padding: 10px 10px 0;overflow-y: auto;margin-right: 20px;
}
.kanban_task{
    -webkit-box-shadow: 0 0 2px 0 red;
    box-shadow: 0 0 2px 0 #b3b3b3,0 2px 2px 0 #b3b3b3;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin-bottom: 15px;
    padding: 12px 16px;
    position: relative;
    -webkit-transition: -webkit-box-shadow .3s;
    transition: -webkit-box-shadow .3s;
    -o-transition: box-shadow .3s;
    cursor: all-scroll;
    min-height: 40px;
    border-radius: 3px;

}
.kanban_link{
    cursor: pointer;
}
.kanban_link:hover{
    color: #64B5F6;
}
.sortable-ghost{
    min-height: 40px;
    opacity: 0.5;
    z-index: 100;
    margin-bottom: 15px;
    border: dashed 2px;
    -webkit-box-shadow: none;
    box-shadow:none;
    border-radius:0;
    -webkit-box-sizing: unset;
    box-sizing: unset;
    padding:0;
}
.sortable-ghost div{
    -webkit-box-shadow: none;
    box-shadow:none;
    min-height: 40px;
    border: 0 !important;

}
.sortable-ghost div div{
    visibility: hidden;
}

.disabled-column {
    opacity: 0.5; /* Затемняет запрещённые колонки */
    pointer-events: none; /* Блокирует перетаскивание */
}

</style>
